<template>
  <div id="apipe-cloud-course-page" style="overflow-x:hidden;">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>优制云</h2>
          <div class="tit">工业互联网领域软件提供商</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>优制云</h4>
          <div class="tit">工业互联网领域软件提供商</div>
        </div>
      </div>

      <div class="about">
        <div class="container">
          <h4>关于优制云</h4>
          <p style="line-height: 1.8rem">
            2021年我国开启了工业互联网发展的“新三年
            ”，我国工业企业及设备上云数量增多。数据显示，我国制造业中较大比例为中小企业，大多企业尚未完成基
            础的设备数字化改造。由此可见，对中小企业来说，工业互联网改造的过程任重道远。
            基于传统企业改革转型的迫切性，在改革开放的先行地深圳
            ，深圳优制云工业互联网有限公司带着制造业经验和成熟的技术双重基因助力中小微企业数字化转型。
            优制云深耕“互联网+行业/产业/工业” ，利用云计算、大数据、互联网、物联网、人工智能、区块链等技术研发构建了优制云工业互联网平台。致力于为广大中小微企业提供综合的、标准的、
            一体化的数智化解决方案，助力中小微企业完成数字化转型。
            优制云工业互联网平台专注聚焦行业现状问题，将技术突破、模式创新与产业实际需求相结合，
            形成了面向特定场景行业的解决方案。
            未来，公司将不断打磨产品，提高平台竞争力，力争与更多企业合作，在全国各大城市建立中小微企业数字赋能实施基地，为中国工业4.0事业做出贡献。
          </p>
        </div>
      </div>

      <div class="pc-titles d-none d-lg-block">
        <div class="row row-cols-1 row-cols-lg-3 g-3 g-lg-3">
          <div class="tit1">
            <h2>公司愿景</h2>
            <h5>成为中小微企业数字化转型一站式解决方案服务商</h5>
          </div>
          <div class="tit2">
            <h2>公司使命</h2>
            <h5>助力中小微企业数字化转型，打造智慧企业</h5>
          </div>
          <div class="tit3">
            <h2>核心价值观</h2>
            <h5>团结、创新、实干、奉献</h5>
          </div>
        </div>
      </div>
      <div class="mobile-titles d-block d-lg-none">
        <div class="row row-cols-1 row-cols-lg-3 g-3 g-lg-3">
          <div class="tit1">
            <h3>公司愿景</h3>
            <h6>成为中小微企业数字化转型一站式解决方案服务商</h6>
          </div>
          <div class="tit2">
            <h3>公司使命</h3>
            <h6>助力中小微企业数字化转型，打造智慧企业</h6>
          </div>
          <div class="tit3">
            <h3>核心价值观</h3>
            <h6>团结、创新、实干、奉献</h6>
          </div>
        </div>
      </div>

      <div class="pc-history d-none d-lg-block">
        <div>
          <h4>发展历程</h4>
          <img src="@/assets/newSite/aboutus/PC-his3.png" alt="" />
        </div>
      </div>
      <div class="mobile-history d-block d-lg-none">
        <div>
          <h4>发展历程</h4>
          <img src="@/assets/newSite/aboutus/mobile(1).png" alt="" />
        </div>
      </div>
    </div>

    <NewFooter class="d-none d-lg-block" />
    <NewFooter @handleClickChild="handleClickChild" class="d-block d-lg-none" :show="false" />
    <MobileFooter ref="mofooter" class="d-block d-lg-none" />
  </div>
</template>
        
  <script>
import { Newslist } from "@/api/fetch";
import Qs from "qs";
export default {
  name: "hardware",
  data() {
    return {
      cur: 1,
      activeName: "1",
    };
  },
  created() {},
  methods: {},
};
</script>
        
        <style lang="less"  scoped>
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/aboutus/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p4.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }
  .about {
    margin: 30px 0;
    p {
      text-align: left;
      text-indent: 2rem;
      // padding: 30px 0;
    }
  }
  .pc-titles {
    color: white;
    .tit1 {
      background-image: url("../../assets/newSite/aboutus/a.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .tit2 {
      background-image: url("../../assets/newSite/aboutus/b.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .tit3 {
      background-image: url("../../assets/newSite/aboutus/c.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .mobile-titles {
    color: white;
    .tit1 {
      background-image: url("../../assets/newSite/aboutus/a.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .tit2 {
      background-image: url("../../assets/newSite/aboutus/b.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .tit3 {
      background-image: url("../../assets/newSite/aboutus/c.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .pc-history {
    padding: 30px 0 0 0;
    img {
      width: 1920px;
      height: 500px;
    }
  }
  .mobile-history {
    padding: 30px 0 0 0;
    border-bottom: 2px solid rgba(20, 132, 255, 1);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
        